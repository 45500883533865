<template>
  <v-container fluid class="px-0 py-0">
    <app-drawer />
    <div :class="`${$vuetify.breakpoint.lgAndUp ? 'content' : ''}`">
      <router-view class="px-5"></router-view>
    </div>
  </v-container>
</template>

<script>
import AppDrawer from "./components/Drawer";

export default {
  components: {
    AppDrawer,
  },
  computed: {},
  data: () => ({}),
  async created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  > div {
    width: 100%;

    .drawer-control {
      display: block;
      background-color: rgba(0, 0, 0, 0.02);
      margin-bottom: 20px;

      h1 {
        padding: 0 30px;
        width: calc(100% - 30px);

        .v-image {
          display: inline-block;
        }
      }
    }

    .content {
      width: calc(100% - 328px);
      margin-left: 328px;
      min-height: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      height: 100vh;
      overflow-y: scroll;

      .drawer-control {
        display: none !important;
      }
    }
  }
}
</style>
